import React, { useState } from 'react';
import Head from 'next/head';
import Layout from '../components/Layout';
const fetch = require('isomorphic-fetch');

import * as queryString from 'query-string';
import TopTerm from '../components/thuatngu/TopTerm';

import Pagination from 'react-js-pagination';
import Search from '../components/Search';
import { isEmpty, numberWithCommas } from '../utils/utils';
import { generationUrl } from '../routines/home_routines';
import { commonConst } from '../constants/constants';
import { Spinner } from 'react-bootstrap';
import { useRouter } from 'next/router';
import { ChevronRight, ChevronLeft, ChevronsRight, ChevronsLeft } from 'react-feather';
import Link from 'next/link';

export async function getServerSideProps(ctx) {
  const { text, hl, alphabet, page, catId } = ctx.query || {};
  const url = generationUrl(text, hl, alphabet, page, catId);
  let terms = null;
  let topTerms = null;
  let cats = null;
  const termResult = await fetch(url);
  if (!isEmpty(termResult)) {
    terms = await termResult.json();
  }
  const topTermsAPI = await fetch(`${process.env.ROOT_API}term/views?pageIndex=1&pageSize=10`);
  if (!isEmpty(topTermsAPI)) {
    topTerms = await topTermsAPI.json();
  }
  const catsAPI = await fetch(`${process.env.ROOT_API}category`);
  if (!isEmpty(catsAPI)) {
    cats = await catsAPI.json();
  }
  return {
    props: {
      terms,
      topTerms,
      cats,
      params: {
        ...(!isEmpty(text) && { text }),
        ...(!isEmpty(hl) && { hl }),
        ...(!isEmpty(alphabet) && { alphabet }),
        ...(!isEmpty(catId) && { catId }),
        ...(!isEmpty(page) && { page }),
      },
    },
  };
}

export default function Home(props) {
  const router = useRouter();
  const { terms, topTerms, cats, params } = props;
  const {
    defaultLang,
    defaultText,
    defaultAlphabet,
    defaultActivePage,
    defaultPageSize,
    defaultCategory,
  } = commonConst;
  const { results, total } = terms;
  const { page: currentPage, hl: currentLanguage, alphabet: currentAlphabet, text: currentText, catId: currentCatId } =
    params || {};
  const [page, setActivePage] = useState(!isEmpty(currentPage) ? currentPage : defaultActivePage);
  // eslint-disable-next-line max-len
  const [alphabet, setAlphabet] = useState(!isEmpty(currentAlphabet) ? currentAlphabet : defaultAlphabet);
  // eslint-disable-next-line max-len
  const [hl, setLanguage] = useState(!isEmpty(currentLanguage) ? currentLanguage : defaultLang);
  const [text, setText] = useState(!isEmpty(currentText) ? currentText : defaultText);
  const [catId, setCategory] = useState(!isEmpty(currentCatId) ? currentCatId : defaultCategory);

  const handlePageChange = value => {
    setActivePage(value);
    const qs = queryString.stringify({
      ...(!isEmpty(alphabet) && { alphabet }),
      ...(!isEmpty(catId) && { catId }),
      ...(!isEmpty(text) && { text }),
      ...(hl === 'en' && { hl }),
      page: value,
    });

    router.push({
      pathname: '/thuat-ngu',
      query: qs,
    });
  };

  return (
    <Layout>
      <Head>
        <title>Từ Điển Tra Cứu Thuật Ngữ Kinh Tế Anh-Việt</title>
        <meta name="description" content="Tổng hợp những dữ liệu về thuật ngữ kinh tế hay và chính xác ở nhiều lĩnh vực như chứng khoán, marketing, quản trị kinh doanh, tài chính - ngân hàng..." />
        <meta property="og:title" content="Từ Điển Tra Cứu Thuật Ngữ Kinh Tế Anh-Việt" />
        <meta property="og:description" content="Tổng hợp những dữ liệu về thuật ngữ kinh tế hay và chính xác ở nhiều lĩnh vực như chứng khoán, marketing, quản trị kinh doanh, tài chính - ngân hàng..." />
        <meta property="og:image" content={`${process.env.WEBSITE_URL}static/img/logo/vnbz-300x300.png`} />
        <meta property="og:url" content={process.env.WEBSITE_URL} />
        <meta property="og:site_name" content="Từ Điển Tra Cứu Thuật Ngữ Kinh Tế Anh-Việt" />
        <script async src="https://www.googletagmanager.com/gtag/js?id=UA-146891693-2" />

        <script
          dangerouslySetInnerHTML={{
            __html: `window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments)}
            gtag('js', new Date());
            gtag('config', 'UA-146891693-2');`,
          }}
        />
      </Head>
      <Search
        alphabet={alphabet}
        catId={catId}
        cats={cats}
        hl={hl}
        text={text}
        setAlphabet={setAlphabet}
        setLanguage={setLanguage}
        setCategory={setCategory}
        setText={setText}
      />
      <div className="container">
        {/* <div className="iframe-thuatngu">
          <iframe src="./widget/thuatngu" width="100%" title frameBorder={0} />
        </div> */}

        <div className="term__content">
          <div className="row ">
            <div className="col-lg-8">
              <div className="pr-lg-4">
                {/* <div className="cluster__heading is__dash__left">
                  {isEmpty(text) && <h2 className="cluster__title text-uppercase ">Danh sách thuật ngữ</h2>}


                </div> */}

                {!isEmpty(text) && (
                  <div className="flex-grow-1 text-left text-result mb-3 ">
                    {numberWithCommas(!isEmpty(total) ? total : 0)} kết quả cho từ khóa
                    <b style={{ color: '#f48320' }}>{isEmpty(text) ? '' : ` "${text}"`}</b>
                  </div>
                )}

                <div className="glossary">
                  {!results && (
                    <div className="loader-placehodler-1 d-flex justify-content-center">
                      <Spinner animation="border" variant="dark" className="opacity-20" />
                    </div>
                  )}

                  {results &&
                    results.length > 0 &&
                    results.map(item => {
                      const { title, titleEn, slug, slugEn, id } = item;
                      let detailLink = `${slug}-${id}`;
                      if (hl === 'en') {
                        detailLink = `${slugEn}-${id}?hl=${hl}`;
                      }
                      return (
                        <div className="glossary__item" key={title}>
                          {/* <FileText className="glossary__item__icon"/>                           */}
                          <div className="name">
                            <Link href={detailLink}>
                              <a>{hl === 'vn' ? title : titleEn}</a>
                            </Link>
                          </div>
                          {/* <div className="desc">{define}</div> */}
                        </div>
                      );
                    })}
                </div>
              </div>
              <div className="text-center mt-4 mb-5">
                <Pagination
                  activePage={parseInt(page, 10)}
                  itemsCountPerPage={defaultPageSize}
                  totalItemsCount={total}
                  pageRangeDisplayed={5}
                  onChange={handlePageChange}
                  prevPageText={<ChevronLeft />}
                  firstPageText={<ChevronsLeft />}
                  lastPageText={<ChevronsRight />}
                  nextPageText={<ChevronRight />}
                  itemClassNext="navi"
                  itemClassPrev="navi"
                />
              </div>
            </div>
            <div className="col-lg-4 pb-4 pb-lg-5">
              <TopTerm hl={hl} terms={topTerms} />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
